const modalState = reactive({
  isOpen: false,
  slug: '',
})

const showModal = (slug: string = '') => Object.assign(modalState, { isOpen: true, slug })
const hideModal = () => modalState.isOpen = false

export {
  hideModal,
  showModal,
  modalState,
}
